<template>
  <keep-alive>
    <router-view v-if="$route.meta.keepAlice"/>
  </keep-alive>
  <router-view v-if="!$route.meta.keepAlice"/>
</template>

<script setup>
import {nextTick, provide, ref} from "vue";

const isRouterActive = ref(true)
provide('reload', () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
})
</script>

<style lang="scss">
body{
  padding: 0;
  margin: 0;
}
#app {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Microsoft Yahei;
  scrollbar-width:none;
  overflow: hidden;
}

/** {*/
/*  scrollbar-width: none;*/
/*}*/

/*::-webkit-scrollbar{*/
/*  display: none;*/
/*}*/

.el-popper .popper__arrow, .el-popper .popper__arrow::after {
  display: none !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-input__inner {
  border-radius: 0 !important;
}

.el-button--mini {
  border-radius: 0 !important;
}

.el-button {
  border-radius: 0 !important;
}

.el-container {
  height: 100% !important;
}

.el-popover {
  min-width: 0px !important;
  padding: 0 !important;
  z-index: 9999 !important;
}

.el-dropdown-menu {
  z-index: 999999 !important;
}

.el-select-dropdown{
  border-radius: 0 !important;
  z-index: 9999 !important;


}

</style>
